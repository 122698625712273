import config from 'src/config/common';
import {
  HOME_ROUTE,
  ROOT_ROUTE,
  LOGIN_ROUTE,
  LOGOUT_ROUTE,
  SIGN_UP_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  PROFILE_ROUTE,
  CHANGE_PASSWORD_ROUTE,
  NOT_FOUND_ROUTE,
} from '@fuse/cognito/dist/constants';

export const routeUrl = {
  notFound: NOT_FOUND_ROUTE,
  root: ROOT_ROUTE,
  home: HOME_ROUTE,
  login: LOGIN_ROUTE,
  logout: LOGOUT_ROUTE,
  signUp: SIGN_UP_ROUTE,
  forgotPassword: FORGOT_PASSWORD_ROUTE,
  changePassword: CHANGE_PASSWORD_ROUTE,
  profile: PROFILE_ROUTE,
  student: {
    inAppWebViewAnnotation: '/annotation/assignment',
    editProfile: '/profile/edit',
    liveClass: {
      root: '/live-class',
      join: '/live-class/join/:classId',
      recorded: '/live-class/recorded',
      viewRecorded: '/live-class/recorded/:classId',
      schedule: '/live-class/schedule',
    },
    gotoZoom: '/go-live/zoom',
    assignment: {
      root: '/assignment',
      feedback: '/assignment/:assignmentId/feedback',
      submission: '/assignment/:assignmentId/submission',
    },
    grade: '/grade',
    gradebook: '/gradebook',
    gradebook_view_detail: '/gradebook/current-acedemic-year/viewDetails',
    classroom: {
      quizzes: {
        list: `/classroom/course/:courseId/quiz`,
        view: `/classroom/course/:courseId/quiz/:quizId`,
        submission: '/classroom/course/:courseId/quiz/:quizId/submission/:quizAttemptId',
      },
      assignments: `/classroom/course/:courseId/assignments`,
      grades: `/classroom/course/:courseId/grades`,
      filePreview: '/classroom/course/:courseId/files/filePreview',
      attendance: '/classroom/attendance',
      course: '/classroom/course',
      announcements: '/classroom/announcements',
      notificationSettings: '/classroom/forumPreference',
    },
    announcements: '/announcements',
    quizzes: {
      list: '/quizzes',
      view: '/quizzes/:quizId',
      submission: '/quizzes/:quizId/submission/:quizAttemptId',
    },
    exams: {
      list: '/exams',
      view: '/exams/:examId',
      resume: '/exams/:examId/:examAttemptId',
      timeUp: '/exams/:examId/timeUp',
      submission: '/exams/:examId/submission/:examAttemptId',
      physicalResume: '/exams/:examId/physical/submission',
    },
    helpdesk: '/helpdesk',
  },
  community: {
    communityHome: '/community',
    feed: '/community/feed',
    groupHome: '/community/group',
    group: '/community/group/:groupId',
    addGroup: '/community/addgroup',
    editGroup: '/community/group/:groupId/editgroup',
  },

  forum: {
    group: '/classroom/course/:courseId',
  },
  parent: {
    sis: '/students/sisoverview/:studentId',
    students: '/students/',
    studentInformation: '/students/studentinformation/:studentId',
    newsFeed: '/newsfeed',
    announcements: '/announcements',
  },
};

// Web sockets
export const wsLink = {
  connectionURL: `${config.rest_url}/fuseclassroom-socket`,
  student: {
    liveClassStatus: '/ws/student/liveClass/status',
  },
  instructor: {},
};

export const FuseConstant = {
  syllabusCourse: '5b51c9a4308b0d1b5b8fb096',
};
